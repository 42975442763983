import React, { Component } from 'react';
import './App.css'; // Import the CSS


export class workInProgress extends Component {
    static displayName = workInProgress.name;

    render() {
        const containerStyle = {
            backgroundColor: '#f0ebe1',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1em', // Added some padding
            boxSizing: 'border-box'
        };

        const titleStyle = {
            color: '#992631',
            fontSize: '2em', // Adjusted size
            fontFamily: 'Knockout-73FullHeviweight',
            marginBottom: '0.5em',
            textAlign: 'center',
            boxSizing: 'border-box'
        };

        const subtitleStyle = {
            color: '#992631',
            fontSize: '1em', // Adjusted size
            fontFamily: 'Knockout-73FullHeviweight',
            textAlign: 'center',
            boxSizing: 'border-box'
        };

        return (
            <>
                <div style={containerStyle}>
                    <div style={titleStyle}>Our Website is Under Maintenance</div>
                    <div style={subtitleStyle}>
                        We're sorry for the inconvenience.<br />Please check back later.
                    </div>
                </div>
            </>
        );
    }
}