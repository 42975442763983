import React, { Component } from 'react';
import { Route } from 'react-router';
import { nps } from './components/nps';
import { question } from './components/question';
import { email } from './components/email';
import { menuBackup } from './components/menuBackup';
import { workInProgress } from './components/workInProgress';

import './custom.css'


export default class App extends Component {
  static displayName = App.name;

    render() {
        document.title = 'miscusi feedback'

        return (
        <>
        <Route exact path='/nps' component={nps} />
        <Route exact path='/question' component={question} />
                <Route exact path='/email' component={email} />
                <Route exact path='/menu-ristorante' component={menuBackup} />
                <Route exact path='/work-in-progress' component={workInProgress} />
        </>
                
        );
  }
}
