import React, { Component } from 'react';

export class menuBackup extends Component {
    static displayName = menuBackup.name;

    render() {



    return (
        <>
            <div className="container">
                <img src="./menuBackup.jpg" />
            </div>
        </>   
    );
  }
}
