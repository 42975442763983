import React, { Component } from 'react';

export class question extends Component {
    static displayName = question.name;


     getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    console.log(vars) 
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
    }



    render() {

    return (
        <>
            <iframe id="typeform-full" width="100%" height="100%" frameBorder="0" allow="camera; microphone; autoplay; encrypted-media;" src={"https://form.typeform.com/to/" + "omfc7LRp" + "?typeform-medium=embed-snippet&uniqueid=" + this.getQueryVariable('uniqueid') + "&storeid=" + this.getQueryVariable('storeid') + "&nps=" + this.getQueryVariable('nps') + "&country=" + this.getQueryVariable('country')  }>
            </iframe>
            <script type="text/javascript" src="https://embed.typeform.com/embed.js">
            </script>
        </>   
    );
  }
}
