import { contains } from 'jquery';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Redirect } from 'react-router-dom'

export class nps extends Component {
    static displayName = nps.name;


    getLanguage() {
        var language = window.navigator.userLanguage || window.navigator.language;
        return language;
    }


    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");


        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { console.log(pair[1]); return pair[1]; }
        }
        return (false);
    }




    getStoreData() {

        let checkpleaseMap = {
            "wMQntXdWS2": "I001",
            "NuObLc2Er7": "I002",
            "ITF5rECex1": "I003",
            "hquADg9r2k": "I004",
            "8eylL7ke4T": "I005",
            "keavsIDFmu": "I006",
            "cC9pda257j": "I007",
            "r0FccIOdja": "I008",
            "RVDEtbcefA": "I009",
            "no8JQcPZsB": "I010",
            "lFYugOakAv": "I011",
            "cLI7koxccN": "I012",
            "WUpw9tyw9O": "I013",
            "T7xN3qJgP9": "I015",
            "3TqY0Ivn8J": "I016",
            "xkxAwETygr": "U001",
            "sfTMJriVO1": "U002",
            "sfTMJriVO2": "I017",
            "W8Dsjnm1V4kA": "I018",
            "TQrr2Tnqrm" : "I019"
        };


        let cps = this.getQueryVariable('cps')
        if (cps.length > 0) {
            return checkpleaseMap[cps]
        } else {
            return this.getQueryVariable('storeid')
        }
    }




    render() {

            return (
                <>
                    <iframe id="typeform-full" width="100%" height="100%" frameBorder="0" allow="camera; microphone; autoplay; encrypted-media;" src={"https://form.typeform.com/to/" + "fE8l6SF4" + "?typeform-medium=embed-snippet&uniqueid=" + uuidv4() + "&storeid=" + this.getStoreData() + "&country=" + this.getQueryVariable('country') + "&language=" + this.getLanguage() + "&cpt=" + this.getQueryVariable('cpt') + "&cps=" + this.getQueryVariable('cps')}>
                    </iframe>
                    <script type="text/javascript" src="https://embed.typeform.com/embed.js">
                    </script>
                </>
            );
        
    }
}
